<template>
  <div v-if="showOverlay">
    <div ref="overlay" class="home__overlay">
      <div class="home__white_dot" @click="handleDotClick"></div>
    </div>
    <!-- Broken tv style second overlay -->
    <!-- <div ref="overlayImg" class="home__broken_tv">
      <div class="r1">
        <div data-anim="tr_u" class="r1_b1 tv_block"></div>
        <div data-anim="tr_u" class="r1_b2 tv_block"></div>
        <div data-anim="tr_b" class="r1_b3 tv_block"></div>
        <div data-anim="tr_r" class="r1_b4 tv_block"></div>
        <div data-anim="tr_l" class="r1_b5 tv_block"></div>
        <div data-anim="tr_r" class="r1_b6 tv_block"></div>
        <div data-anim="tr_b" class="r1_b7 tv_block"></div>
        <div data-anim="tr_b" class="r1_b8 tv_block"></div>
        <div data-anim="tr_l" class="r1_b9 tv_block"></div>
      </div>
      <div class="r2">
        <div data-anim="tr_u" class="r2_b1 tv_block"></div>
        <div data-anim="tr_r" class="r2_b2 tv_block"></div>
        <div data-anim="tr_l" class="r2_b3 tv_block"></div>
        <div data-anim="tr_b" class="r2_b4 tv_block"></div>
      </div>
      <div class="r3">
        <div data-anim="tr_b" class="r3_b1 tv_block"></div>
        <div data-anim="tr_b" class="r3_b2 tv_block"></div>
        <div data-anim="tr_b" class="r3_b3 tv_block"></div>
        <div data-anim="tr_b" class="r3_b4 tv_block"></div>
      </div>
      <div class="r4">
        <div data-anim="tr_u" class="r4_b1 tv_block"></div>
        <div data-anim="tr_b" class="r4_b2 tv_block"></div>
        <div data-anim="tr_l" class="r4_b3 tv_block"></div>
        <div data-anim="tr_l" class="r4_b4 tv_block"></div>
        <div data-anim="tr_l" class="r4_b5 tv_block"></div>
        <div data-anim="tr_r" class="r4_b6 tv_block"></div>
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  name: "BlackOverlay",
  data() {
    return {
      ls: "isVisited",
      showOverlay: true,
      ttlDays: 1,
    };
  },
  mounted() {
    try {
      const isVisited = JSON.parse(localStorage.getItem(this.ls));
      const now = new Date();
      if ((isVisited.ttl + this.ttlDays) > now.getDate()) {
        this.showOverlay = false;
      } else {
        this.showOverlay = true;
      }
    } catch (e) {
      console.log("Welcome visitor!");
    }

    setTimeout(() => {
      this.handleDotClick()
    }, 10000)
  },
  methods: {
    handleDotClick() {
      const ovrl = this.$refs.overlay;
      const ovrlImg = this.$refs.overlayImg;
      ovrl.style.transition = "opacity 1s ease-in";
      ovrl.style.opacity = "0"

      setTimeout(() => {
        ovrl.style.zIndex = "1";
      }, 1000);
      setTimeout(() => {
        const now = new Date();
        const lsObject = { isVisited: true, ttl: now.getDate()};
        localStorage.setItem(this.ls, JSON.stringify(lsObject));

        ovrlImg.style.zIndex = "1";
      }, 3500);

      setTimeout(() => {
        const tvBlocks = document.querySelectorAll(".tv_block");
        for (var i = 0; i < tvBlocks.length; i++) {
          const animClass = tvBlocks[i].getAttribute("data-anim");
          tvBlocks[i].classList.add(animClass);
        }
      }, 1200);
    },
  },
};
</script>
